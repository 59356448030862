import { Alert } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { useCartLineCardContext } from "../CartLineCard";

export function CartLineCardPackageMessage() {
    const { t } = useTranslation();
    const { line } = useCartLineCardContext();

    if (!line.dispo || !line.message_package) {
        return null;
    }

    return (
        <Alert severity="info" title={t("Bon à savoir")}>
            {line.message_package}
        </Alert>
    );
}
