import { useParams } from "react-router-dom";
import { Spinner } from "@resamare/ui";
import { LiaisonForm, LiaisonFormProps } from "../components/LiaisonForm/LiaisonForm";
import { useDefaultConfigSearch } from "../../../client-details";
import { useGetParticipantTypes } from "../../../participant-types";
import { useGetDepartureLocations } from "../../../departure-locations";
import { useGetProductType } from "../../../product-types";
import { getLiaisonSchema } from "../validation/liaisonSchema";

type LiaisonFormContainerProps = Pick<LiaisonFormProps, "isLoading" | "onSubmit">;

export function LiaisonFormContainer({ isLoading, onSubmit }: LiaisonFormContainerProps) {
    const { productTypeId } = useParams() as { productTypeId: string };
    const participantTypesQuery = useGetParticipantTypes({ productTypeId });
    const locationsQuery = useGetDepartureLocations({ productTypeId });
    const productTypesQuery = useGetProductType(productTypeId);

    const defaultSearch = useDefaultConfigSearch();

    if (
        participantTypesQuery.isPending ||
        locationsQuery.isPending ||
        productTypesQuery.isPending
    ) {
        return <Spinner />;
    }

    if (!participantTypesQuery.data || !locationsQuery.data || !productTypesQuery.data) {
        return null;
    }

    const schema = getLiaisonSchema(locationsQuery.data, participantTypesQuery.data);

    return (
        <LiaisonForm
            key={productTypeId}
            defaultValues={{ ...defaultSearch, type_produit: parseInt(productTypeId, 10) }}
            departureLocations={locationsQuery.data}
            isLoading={isLoading}
            onSubmit={onSubmit}
            participantTypes={participantTypesQuery.data}
            roundTripOnly={productTypesQuery.data.ar_seulement}
            schema={schema}
        />
    );
}
