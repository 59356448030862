import React, { PropsWithChildren } from "react";
import { MemoryRouter as Router } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { ErrorBoundary, FullPageSpinner, ToastRenderer } from "@resamare/ui";
import {
    ClientConfigProvider,
    ThemeProvider,
    initApiClient,
    logError,
    queryClient,
} from "@resamare/widgets-shared";
import { TranslationProvider } from "@resamare/translation";
import { useInjectStyleSheet } from "@resamare/react-hooks";

import { WIDGET_ASSETS_URL } from "@/config/constants";
import { useWidgetAttributes } from "@/hooks/useWidgetAttributes";

export function AppProvider({ children }: PropsWithChildren) {
    useInjectStyleSheet(`${WIDGET_ASSETS_URL}/recherche/index.css`);
    const attributes = useWidgetAttributes();
    initApiClient(attributes);

    return (
        <React.Suspense fallback={<FullPageSpinner />}>
            <ThemeProvider config={attributes}>
                <ErrorBoundary onError={logError}>
                    <TranslationProvider defaultLanguage={attributes.langue}>
                        <QueryClientProvider client={queryClient}>
                            <ReactQueryDevtools />
                            <ClientConfigProvider>
                                <Router>{children}</Router>
                            </ClientConfigProvider>
                        </QueryClientProvider>
                    </TranslationProvider>
                </ErrorBoundary>
                <ToastRenderer />
            </ThemeProvider>
        </React.Suspense>
    );
}
