import { createContext, useContext, ReactNode } from "react";
import { BaseWidgetAttributes, ExtendedWidgetAttributes } from "./baseWidgetSchema";

export const WidgetAttributesContext = createContext<
    BaseWidgetAttributes | ExtendedWidgetAttributes | null
>(null);

type WidgetAttributesProviderProps<T extends BaseWidgetAttributes | ExtendedWidgetAttributes> = {
    attributes: T;
    children: ReactNode;
};

export function WidgetAttributesProvider<
    T extends BaseWidgetAttributes | ExtendedWidgetAttributes,
>({ attributes, children }: WidgetAttributesProviderProps<T>) {
    return (
        <WidgetAttributesContext.Provider value={attributes}>
            {children}
        </WidgetAttributesContext.Provider>
    );
}

export function useWidgetAttributes<T extends BaseWidgetAttributes | ExtendedWidgetAttributes>() {
    const context = useContext(WidgetAttributesContext);

    if (!context) {
        throw new Error("useWidgetAttributes must be used within a WidgetAttributesProvider");
    }

    return context as T;
}
