import { formatCurrency } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { Stack, Typography } from "@resamare/ui";
import { useCartLineCardContext } from "../CartLineCard";

export function CartLineCardSubtotal() {
    const { t } = useTranslation();
    const { line } = useCartLineCardContext();

    return (
        <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight={600}>{t("Sous-total")}</Typography>
            <Typography fontWeight={600}>{formatCurrency(line.total)}</Typography>
        </Stack>
    );
}
