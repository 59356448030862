import { Stack } from "@resamare/ui";
import { CartLine, PackageLine } from "../../../cart";
import { Cart } from "../../../../models";
import { MeetchContainer } from "../../../options";

import styles from "./OrderSummaryProducts.module.scss";

type OrderSummaryProductsProps = {
    cart: Cart;
};

export function OrderSummaryProducts({ cart }: OrderSummaryProductsProps) {
    return (
        <Stack className={styles["order-summary-products"]} gap={3}>
            {cart.ligne_package.map((line) => (
                <div key={line.id} className={styles["order-summary-line-container"]}>
                    <PackageLine line={line} />
                    <div className={styles["order-summary-meetch"]}>
                        <MeetchContainer line={line} />
                    </div>
                </div>
            ))}

            {cart.ligne_panier.map((line) => (
                <div key={line.id} className={styles["order-summary-line-container"]}>
                    <CartLine line={line} />
                    <div className={styles["order-summary-meetch"]}>
                        <MeetchContainer line={line} />
                    </div>
                </div>
            ))}
        </Stack>
    );
}
