import { renderElementsWithDividers, Stack } from "@resamare/ui";
import { CartLineCard, useCartLineCardContext } from "../CartLineCard";
import { renderSessionsWithSeparator } from "./renderSessionsWithSeparator";

export function CartLineCardListSessions() {
    const { line } = useCartLineCardContext();

    if (!line.liste_session) {
        return null;
    }

    return (
        <Stack gap={0.75}>
            {renderSessionsWithSeparator(
                line.liste_session.map((session) => (
                    <CartLineCard key={session.id} line={session}>
                        <CartLineCard.Image />
                        <CartLineCard.Content>
                            {renderElementsWithDividers([
                                <CartLineCard.Infos key="infos" />,
                                session.options.length ? (
                                    <CartLineCard.Options key="options" />
                                ) : null,
                            ])}
                        </CartLineCard.Content>
                    </CartLineCard>
                )),
            )}
        </Stack>
    );
}
