import { ButtonLink, Stack } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { useCartLineCardContext } from "../CartLineCard";

export function CartLineCardDelete() {
    const { t } = useTranslation();
    const { deleteLine, isLoading } = useCartLineCardContext();

    return (
        <Stack alignItems="flex-end">
            <ButtonLink color="error" isLoading={isLoading} onClick={deleteLine} variant="text-sm">
                {t("Supprimer")}
            </ButtonLink>
        </Stack>
    );
}
