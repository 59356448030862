import { Button, InfoIcon, Stack, Tooltip, Typography } from "@resamare/ui";
import { formatCurrency } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";

import styles from "./PackageCard.module.scss";
import { useClientConfig } from "../../../client-details";
import { Package } from "../../../../models";

type PackageCardFooterProps = {
    packageItem: Package;
    onClick: (packageItem: Package) => void;
    priceFrom?: number;
};

export function PackageCardFooter({ onClick, packageItem, priceFrom }: PackageCardFooterProps) {
    const { t } = useTranslation();

    const config = useClientConfig();

    return (
        <Stack className={styles["package-card__footer"]} gap={2}>
            {priceFrom ? (
                <Stack alignItems="flex-start">
                    <Stack alignItems="center" direction="row" gap={0.5}>
                        <Typography variant="text-sm">{t("À partir de")}</Typography>
                        <Tooltip
                            title={t(
                                "Ce prix est à titre indicatif et peut varier selon la période de réservation",
                            )}
                        >
                            <InfoIcon size="small" />
                        </Tooltip>
                    </Stack>
                    <Stack alignItems="baseline" direction="row" gap={1}>
                        <Typography variant="title-sm">{formatCurrency(priceFrom)}</Typography>
                    </Stack>
                </Stack>
            ) : null}
            <Button onClick={() => onClick(packageItem)}>
                {config.activer_panier ? t("Ajouter au panier") : t("Réserver")}
            </Button>
        </Stack>
    );
}
