import { useGetOptions } from "../api";
import { ProductAPI } from "../types";

type UseMeetchOptions = {
    productIds?: string[];
    enabled?: boolean;
};

export function useMeetch({ productIds, enabled = true }: UseMeetchOptions = {}) {
    const optionsQuery = useGetOptions({ productIds }, { enabled });

    const meetchOption = optionsQuery.data?.find(
        (option) => option.produit_api === ProductAPI.MEETCH,
    );

    return {
        meetchOption,
        isLoading: optionsQuery.isPending,
    };
}
