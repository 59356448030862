import { useTranslation } from "@resamare/translation";
import { Stack, Typography } from "@resamare/ui";
import { formatCurrency } from "@resamare/functions";
import { useCartLineCardContext } from "../CartLineCard";

export function CartLineCardDiscount() {
    const { t } = useTranslation();
    const { line } = useCartLineCardContext();

    if (!line.promotion) return null;

    const { promotion } = line;

    return (
        <Stack>
            <Stack direction="row" gap={1}>
                <Typography fontWeight={600}>{t("Réduction")}</Typography>
            </Stack>
            <Stack direction="row" gap={1} justifyContent="space-between">
                <Typography>{promotion.label}</Typography>
                <Typography color="success">{formatCurrency(promotion.total)}</Typography>
            </Stack>
        </Stack>
    );
}
