import { CreateCartLineDTO, useCreateCartLineMutation } from "../../cart";
import { useMeetch } from "./useMeetch";
import { useNavigateWithPrevious } from "../../../hooks";
import { useClientConfig } from "../../client-details";
import { Path } from "../../../routes";

export function useCreateCartLineAndNavigate() {
    const createCartLineMutation = useCreateCartLineMutation();
    const navigate = useNavigateWithPrevious();
    const config = useClientConfig();

    const { meetchOption, isLoading } = useMeetch();

    const navigateToNextStep = () => {
        if (meetchOption || config.activer_panier) {
            navigate(Path.CHECKOUT_SUMMARY);
            return;
        }
        navigate(Path.CHECKOUT_FORM);
    };

    const handleCreateCartLine = (params: CreateCartLineDTO | CreateCartLineDTO[]) => {
        createCartLineMutation.mutate(params, {
            onSuccess: navigateToNextStep,
        });
    };

    return { handleCreateCartLine, isLoading: isLoading || createCartLineMutation.isPending };
}
