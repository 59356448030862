import { useWindowSize, useElementSize } from "@resamare/react-hooks";
import { useWidgetAttributes } from "../../features/widget-attributes";
import { ExtendedWidgetAttributes } from "../../features/widget-attributes/baseWidgetSchema";

import styles from "./AsideLayout.module.scss";

type AsideLayoutProps = {
    children: React.ReactNode;
    className?: string;
};

export function AsideLayout({ children, className = "" }: AsideLayoutProps) {
    const attributes = useWidgetAttributes<ExtendedWidgetAttributes>();
    const { height: windowHeight } = useWindowSize();
    const [asideContentRef, { height: asideContentHeight }] = useElementSize();

    const VERTICAL_PADDING = 64;
    const stickOnBottom =
        (asideContentHeight || 0) >
        (windowHeight || 0) - VERTICAL_PADDING - (attributes.offsetTop || 0);

    const top = attributes.offsetTop ? `calc(2em + ${attributes.offsetTop || 0}px)` : "2em";
    const style = stickOnBottom ? { bottom: "2em" } : { top };

    return (
        <div className={`${styles["aside"]} ${className}`}>
            {stickOnBottom ? <div style={{ flex: 1 }} /> : null}
            <div ref={asideContentRef} className={styles["aside__content"]} style={style}>
                {children}
            </div>
        </div>
    );
}
