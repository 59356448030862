import { useState } from "react";
import { dt } from "@resamare/functions";
import { Liaison as ILiaison } from "../../../../models";
import { LiaisonCard } from "./LiaisonCard/LiaisonCard";
import { LiaisonOptions } from "./LiaisonOptions";
import { SelectedLiaison } from "../types";
import { LiaisonSearch, useSearchContext } from "../../../search";
import { usePrefetchSessionOptionsForm } from "../../../sessions";
import { useHasOptionsToShow } from "../../../options";

type LiaisonProps = {
    liaison: ILiaison;
    onSelect: (data: SelectedLiaison) => void;
};

export function Liaison({ liaison, onSelect }: LiaisonProps) {
    const { search } = useSearchContext<LiaisonSearch>() as { search: LiaisonSearch };

    const [isOpen, setIsOpen] = useState(false);
    const closeOptions = () => setIsOpen(false);
    const openOptions = () => setIsOpen(true);

    usePrefetchSessionOptionsForm([liaison.id], { participants: search.participants });
    const hasOptions = useHasOptionsToShow([liaison], search.participants);

    const handleOnClick = () => {
        if (hasOptions) {
            openOptions();
            return;
        }

        const data = {
            date_fin: dt.fromISO(dt.removeIsoOffset(liaison.date_fin)),
            lieu_id: liaison.lieux_depart[0].value.toString(),
            participants: search.participants,
            session_id: liaison.id,
            prix_apartirde: liaison.prix_apartirde || liaison.prix_total,
            liaison,
        };

        onSelect(data);
    };

    return (
        <>
            <LiaisonCard liaison={liaison} onClick={handleOnClick} />
            <LiaisonOptions
                isOpen={isOpen}
                liaison={liaison}
                onClose={closeOptions}
                onSelect={onSelect}
            />
        </>
    );
}
