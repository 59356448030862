export enum Path {
    SEARCH = "/search",

    PARTICIPANTS = "/participants",
    OPTIONS = "/options",
    ADDITIONNAL_OPTIONS = "/additionnal-options",

    CHECKOUT_SUMMARY = "/checkout-summary",
    CHECKOUT_FORM = "/checkout-form",
    CHECKOUT_CONFIRMATION = "/checkout-confirmation",
}
