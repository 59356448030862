import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { Card } from "@resamare/ui";
import { storage } from "@resamare/functions";

import { CartLine } from "../../../../models";
import { CartLineCardAvailability } from "./CartLineCardAvailability/CartLineCardAvailability";
import { CartLineCardContent } from "./CartLineCardContent/CartLineCardContent";
import { CartLineCardDelete } from "./CartLineCardDelete/CartLineCardDelete";
import { CartLineCardDiscount } from "./CartLineCardDiscount/CartLineCardDiscount";
import { CartLineCardImage } from "./CartLineCardImage/CartLineCardImage";
import { CartLineCardInfos } from "./CartLineCardInfos/CartLineCardInfos";
import { CartLineCardOptions } from "./CartLineCardOptions/CartLineCardOptions";
import { CartLineCardPackageMessage } from "./CartLineCardPackageMessage/CartLineCardPackageMessage";
import { CartLineCardParticipants } from "./CartLineCardParticipants/CartLineCardParticipants";
import { CartLineCardSubtotal } from "./CartLineCardSubtotal/CartLineCardSubtotal";
import { useDeleteCartLine } from "../../api/deleteCartLine";
import { CartLineCardTitle } from "./CartLineCardTitle/CartLineCardTitle";
import { CartLineCardListSessions } from "./CartLineCardListSessions/CartLineCardListSessions";

import styles from "./CartLineCard.module.scss";

type CartLineCardContext = {
    deleteLine: () => void;
    isLoading: boolean;
    line: CartLine;
};

const CartLineCardContext = createContext<CartLineCardContext | undefined>(undefined);

export function useCartLineCardContext() {
    const context = useContext(CartLineCardContext);
    if (!context) {
        throw new Error("useCartLineCardContext must be used within a CartLineCard");
    }

    return context;
}

type CartLineCardProps = PropsWithChildren & {
    line: CartLine;
    variant?: React.ComponentProps<typeof Card>["variant"];
};

export function CartLineCard({ children, line, variant }: CartLineCardProps) {
    const deleteMutation = useDeleteCartLine();

    const handleOnDelete = () => {
        const cartKey = storage.getWithExpiry<string>("cart");
        if (!cartKey) throw new Error("Cart not found");
        deleteMutation.mutate({ cartKey, lineId: line.id });
    };

    const contextValue = useMemo(
        () => ({
            isLoading: deleteMutation.isPending,
            deleteLine: handleOnDelete,
            line,
        }),
        [line, handleOnDelete],
    );

    return (
        <CartLineCardContext.Provider value={contextValue}>
            <Card className={styles["cart-line"]} variant={variant}>
                {children}
            </Card>
        </CartLineCardContext.Provider>
    );
}

CartLineCard.Availability = CartLineCardAvailability;
CartLineCard.Content = CartLineCardContent;
CartLineCard.Delete = CartLineCardDelete;
CartLineCard.Discount = CartLineCardDiscount;
CartLineCard.Image = CartLineCardImage;
CartLineCard.Infos = CartLineCardInfos;
CartLineCard.ListSessions = CartLineCardListSessions;
CartLineCard.Options = CartLineCardOptions;
CartLineCard.PackageMessage = CartLineCardPackageMessage;
CartLineCard.Participants = CartLineCardParticipants;
CartLineCard.Subtotal = CartLineCardSubtotal;
CartLineCard.Title = CartLineCardTitle;
