import axios from "axios";
import { BaseWidgetAttributes } from "../features/widget-attributes/baseWidgetSchema";

export const apiClient = axios.create({});

export const initApiClient = (config: BaseWidgetAttributes) => {
    apiClient.defaults.baseURL = config.api;
    apiClient.defaults.headers["Resamare-Langue"] = config.langue || "fr";

    if (config.partenaireId) {
        apiClient.defaults.headers["Resamare-Partenaire-Id"] = config.partenaireId;
    }
};
