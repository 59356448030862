import { ReactElement } from "react";
import { Divider } from "./Divider";

export function renderElementsWithDividers(elements: (ReactElement | null)[]): ReactElement[] {
    const validElements = elements.filter((element) => element !== null);

    return validElements.reduce<ReactElement[]>((acc, element, index) => {
        acc.push(element);

        // Ajoute un Divider après chaque élément, sauf après le dernier
        if (index < validElements.length - 1) {
            acc.push(<Divider key={`${element.key || index}-divider`} />);
        }

        return acc;
    }, []);
}
