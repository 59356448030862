import { Spinner, Stack, Typography } from "@resamare/ui";
import { useEffect } from "react";
import { isEmpty } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { useScrollToRef } from "@resamare/react-hooks";
import { ExcursionsList } from "../components/ExcursionsList";
import { ExcursionSearch, useSearchContext } from "../../../search";
import { useGetSessions } from "../../../sessions";
import { Section } from "../../../../components";
import { useWidgetAttributes } from "../../../widget-attributes";
import { ExtendedWidgetAttributes } from "../../../widget-attributes/baseWidgetSchema";

export function ExcursionsPage() {
    const { t } = useTranslation();
    const attributes = useWidgetAttributes<ExtendedWidgetAttributes>();
    const { search } = useSearchContext<ExcursionSearch>();
    const sessionsQuery = useGetSessions({ params: search });

    const scrollOffset = attributes.offsetTop || 0 + 32;
    const { ref, scrollToRef } = useScrollToRef({ offsetTop: -scrollOffset });

    useEffect(() => {
        if (!isEmpty(sessionsQuery.data) && ref.current) {
            scrollToRef();
        }
    }, [ref, scrollToRef, sessionsQuery.data]);

    if (!sessionsQuery.data || !search) {
        return null;
    }

    return (
        <Section>
            <Stack gap={3}>
                {sessionsQuery.isFetching ? <Spinner /> : null}

                <div ref={ref}>
                    {isEmpty(sessionsQuery.data) ? (
                        <Typography>{t("Aucun résultat")}</Typography>
                    ) : (
                        <ExcursionsList products={sessionsQuery.data} />
                    )}
                </div>
            </Stack>
        </Section>
    );
}
