import { Spinner, Stack } from "@resamare/ui";
import { isEmpty } from "@resamare/functions";
import { useNavigate } from "react-router-dom";
import { EmptyCartMessage, useGetCart } from "../../cart";
import { Section } from "../../../components";
import { usePreviousLocation } from "../../../hooks";
import { TwoColumnsLayout } from "../../../layouts";
import { OrderSummaryProducts } from "../components/OrderSummary/OrderSummaryProducts";
import { OrderSummaryAside } from "../components/OrderSummary/OrderSummaryAside";

export function CheckoutSummaryPage() {
    const navigate = useNavigate();
    const previousLocation = usePreviousLocation();

    const cartQuery = useGetCart();

    if (cartQuery.isPending) {
        return (
            <Section>
                <Spinner />
            </Section>
        );
    }

    if (
        !cartQuery.isPending &&
        (!cartQuery.data ||
            (isEmpty(cartQuery.data.ligne_package) && isEmpty(cartQuery.data.ligne_panier)))
    ) {
        return (
            <Section>
                <Stack gap={5}>
                    {cartQuery.isFetching ? <Spinner /> : null}
                    <EmptyCartMessage onClick={() => navigate(previousLocation.pathname)} />
                </Stack>
            </Section>
        );
    }

    return (
        <Section>
            <Stack gap={5}>
                {cartQuery.isFetching ? <Spinner /> : null}
                <TwoColumnsLayout>
                    <OrderSummaryProducts cart={cartQuery.data} />
                    <OrderSummaryAside cart={cartQuery.data} />
                </TwoColumnsLayout>
            </Stack>
        </Section>
    );
}
