import { useController } from "react-hook-form";
import { FieldWrapper } from "../FieldWrapper/FieldWrapper";
import { RadioGroup, RadioGroupProps } from "./RadioGroup";
import { RadioOption } from "./types";

interface FormRadioProps<OptionType extends RadioOption>
    extends Pick<
        RadioGroupProps<OptionType>,
        "defaultValue" | "helperText" | "label" | "name" | "options" | "renderOption" | "row"
    > {
    onChange?: (value: string, currentValue: string) => void;
}

export function FormRadio<OptionType extends RadioOption>({
    defaultValue,
    helperText,
    label,
    name,
    onChange,
    options,
    renderOption,
    row,
}: FormRadioProps<OptionType>) {
    const defaultOption = options.find((option) => option.disabled !== true);

    const { field, fieldState } = useController({
        name,
        defaultValue: defaultValue || defaultOption?.value,
    });

    return (
        <FieldWrapper name={field.name}>
            <RadioGroup
                helperText={helperText}
                inputRef={field.ref}
                isError={!!fieldState.error}
                label={label}
                name={field.name}
                onChange={(e) => {
                    if (onChange) {
                        onChange(e.target.value, field.value);
                    }
                    field.onChange(e.target.value);
                }}
                options={options}
                renderOption={renderOption}
                row={row}
                value={field.value}
            />
        </FieldWrapper>
    );
}
