import { isEqual } from "@resamare/functions";
import {
    LiaisonSearch,
    getOutboundParams,
    useGetLiaisons,
    useSearchContext,
    LiaisonFormContainer,
} from "@resamare/widgets-shared";

export function LiaisonForm() {
    const { search, setSearch } = useSearchContext<LiaisonSearch>();
    const outboundQuery = useGetLiaisons({ params: getOutboundParams(search) });

    const onSubmit = (data: LiaisonSearch) => {
        if (isEqual(data, search)) outboundQuery.refetch();
        setSearch(data);
    };

    return <LiaisonFormContainer isLoading={outboundQuery.isFetching} onSubmit={onSubmit} />;
}
