/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { ThemeProvider as ResamareThemeProvider, ThemeOptions } from "@resamare/ui";
import { BaseWidgetAttributes } from "../features/widget-attributes/baseWidgetSchema";

type ThemeProviderProps = {
    children: React.ReactNode;
    config: BaseWidgetAttributes;
};

export function ThemeProvider({ children, config }: ThemeProviderProps) {
    const theme: ThemeOptions = {
        colorSchemes: {
            light: {
                palette: {
                    primary: {
                        main: getColor(config.couleurPrincipale) || "#2b3b61",
                        contrastText: getColor(config.couleurComplementaire) || "#fff",
                    },
                },
            },
        },
    };

    return (
        <ResamareThemeProvider scoped scopedContainer theme={theme}>
            {children}
        </ResamareThemeProvider>
    );
}

function getColor(cssVariableOrColor?: string) {
    if (!cssVariableOrColor) return null;

    if (cssVariableOrColor.startsWith("var(")) {
        const varName = cssVariableOrColor.slice(4, -1).trim();
        const value = getComputedStyle(document.documentElement).getPropertyValue(varName).trim();
        return value;
    }

    return cssVariableOrColor;
}
