import { NavibusFormContainer, NavibusSearch, useSearchContext } from "@resamare/widgets-shared";
import { useIsFetching } from "@tanstack/react-query";

export function NavibusForm() {
    const { setSearch } = useSearchContext<NavibusSearch>();
    const isFetchingSessions = useIsFetching({ queryKey: ["navibus-sessions"] });

    const handleOnSubmit = (data: NavibusSearch) => {
        setSearch(data);
    };

    return <NavibusFormContainer isLoading={isFetchingSessions > 0} onSubmit={handleOnSubmit} />;
}
