import { Dialog, Stack, Typography } from "@resamare/ui";
import { useState } from "react";
import { useTranslation } from "@resamare/translation";
import { ModalLayout } from "../../../../layouts";
import { LoupIllustration } from "../../../../assets";

export function NoResultFound() {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(true);

    const handleOnClose = () => {
        setIsOpen(false);
    };

    return (
        <Dialog isOpen={isOpen} onClose={handleOnClose}>
            <ModalLayout>
                <Stack alignItems="center" gap={2}>
                    <LoupIllustration />
                    <div>
                        <Typography align="center" variant="title-xs">
                            {t("Désolé")} !
                        </Typography>
                        <Typography align="center">
                            {t("Aucun résultat ne semble correspondre à vos critères.")}
                        </Typography>
                    </div>
                </Stack>
            </ModalLayout>
        </Dialog>
    );
}
