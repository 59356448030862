export function normalizeWidgetData(value: object) {
    if (typeof value !== "object" || value === null) {
        return value;
    }

    return Object.fromEntries(
        Object.entries(value).map(([key, val]) => {
            switch (key) {
                case "couleurComplementaire":
                case "couleurPrincipale":
                case "langue":
                case "partenaireId":
                case "offsetTop":
                case "recherche":
                case "typeProduit":
                case "tokenReservation":
                    return [key, val.trim() === "" ? undefined : val.trim()];

                default:
                    return [key, val.trim()];
            }
        }),
    );
}
