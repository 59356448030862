/* eslint-disable @typescript-eslint/naming-convention */
import { Stack, Typography } from "@resamare/ui";
import { formatCurrency } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { AsideLayout } from "../../../../layouts";
import { CartLine, PackageLine } from "../../../cart";
import { Cart } from "../../../../models";

import styles from "./CheckoutAside.module.scss";
import { DiscountCodes } from "../../../promo-code";

type CheckoutAsideProps = {
    cart: Cart;
};

export function CheckoutAside({ cart }: CheckoutAsideProps) {
    const { t } = useTranslation();

    const { montant_regle, total_panier } = cart;

    const total = parseFloat(total_panier);

    return (
        <AsideLayout className={styles["infos-aside"]}>
            <Stack gap={3}>
                <Typography variant="title-xs">{t("Détails de la commande")}</Typography>
                {cart.ligne_package.map((line) => (
                    <PackageLine key={line.id} canDelete={false} line={line} />
                ))}

                {cart.ligne_panier.map((line) => (
                    <CartLine key={line.id} canDelete={false} line={line} />
                ))}

                <DiscountCodes cart={cart} />

                {montant_regle > 0 ? (
                    <Stack direction="row" justifyContent="space-between">
                        <Typography color="success" variant="title-xs">
                            {t("Montant déjà réglé")}
                        </Typography>
                        <Typography color="success" variant="title-xs">
                            - {formatCurrency(montant_regle)}
                        </Typography>
                    </Stack>
                ) : null}

                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="title-xs">
                        {montant_regle > 0 ? t("Reste à payer") : t("Montant total")}
                    </Typography>
                    <Typography variant="title-xs">{formatCurrency(total)}</Typography>
                </Stack>
            </Stack>
        </AsideLayout>
    );
}
