import {
    extendedWidgetSchema,
    useWidgetAttributes as useSharedWidgetAttributes,
} from "@resamare/widgets-shared";
import { InferType } from "yup";

export const searchWidgetSchema = extendedWidgetSchema;

export type SearchWidgetAttributes = InferType<typeof searchWidgetSchema>;

export function useWidgetAttributes() {
    return useSharedWidgetAttributes<SearchWidgetAttributes>();
}
