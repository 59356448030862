import { useState } from "react";
import { ButtonLink, Drawer, Spinner, Stack, Typography } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { formatCurrency } from "@resamare/functions";
import { CartLine, PackageLine } from "../../../cart";
import { OverlayHeader } from "../../../../components";
import { ModalLayout, TwoColumnsLayout } from "../../../../layouts";
import { CheckoutForm } from "../CheckoutForm/CheckoutForm";
import { AdditionalInfoType, useGetAdditionalInfos } from "../../../additional-infos";
import { CheckoutAside } from "../CheckoutAside/CheckoutAside";
import { Cart } from "../../../../models";

import styles from "./Checkout.module.scss";

type CheckoutProps = {
    cart: Cart;
};

export function Checkout({ cart }: CheckoutProps) {
    const { t } = useTranslation();

    const participantsInfosQuery = useGetAdditionalInfos(
        cart.panier_cle,
        AdditionalInfoType.PARTICIPANT,
    );

    const reservationInfosQuery = useGetAdditionalInfos(
        cart.panier_cle,
        AdditionalInfoType.RESERVATION,
    );

    const [isOpen, setIsOpen] = useState(false);
    const handleOnClose = () => setIsOpen(false);

    const total = parseFloat(cart.total_panier);

    if (participantsInfosQuery.isPending || reservationInfosQuery.isPending) {
        return <Spinner />;
    }

    return (
        <TwoColumnsLayout>
            <Stack className={styles["infos-container"]} gap={3}>
                <Stack className={styles["infos-mobile"]} gap={1}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="title-xs">{t("Montant total")}</Typography>
                        <Typography variant="title-xs">{formatCurrency(total)}</Typography>
                    </Stack>
                    <ButtonLink onClick={() => setIsOpen(true)}>
                        {t("Afficher les détails de la commande")}
                    </ButtonLink>
                    <Drawer isOpen={isOpen} maxWidth={500} onClose={handleOnClose}>
                        <OverlayHeader onClose={handleOnClose}>
                            {t("Détails de la commande")}
                        </OverlayHeader>
                        <ModalLayout>
                            <Stack gap={3}>
                                {cart.ligne_package.map((line) => (
                                    <PackageLine key={line.id} canDelete={false} line={line} />
                                ))}

                                {cart.ligne_panier.map((line) => (
                                    <CartLine key={line.id} canDelete={false} line={line} />
                                ))}
                            </Stack>
                        </ModalLayout>
                    </Drawer>
                </Stack>

                {participantsInfosQuery.data && reservationInfosQuery.data ? (
                    <CheckoutForm
                        cart={cart}
                        participantsInfos={participantsInfosQuery.data}
                        reservationInfos={reservationInfosQuery.data}
                    />
                ) : null}
            </Stack>
            <CheckoutAside cart={cart} />
        </TwoColumnsLayout>
    );
}
