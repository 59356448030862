import { useTranslation } from "@resamare/translation";
import { Button, Form, FormError, FormHidden, FormNumberStepper, Stack } from "@resamare/ui";
import { SubmitHandler } from "react-hook-form";
import { PackageSearch } from "../../search";
import { ParticipantType } from "../../participant-types";
import { getPackageSchema } from "../validation/packageSchema";

export type PackageFormProps = {
    defaultValues: Partial<PackageSearch>;
    isLoading: boolean;
    onSubmit: SubmitHandler<PackageSearch>;
    participantTypes: ParticipantType[];
};

export function PackageForm({
    defaultValues,
    isLoading,
    onSubmit,
    participantTypes,
}: PackageFormProps) {
    const { t } = useTranslation();

    const schema = getPackageSchema(participantTypes);

    return (
        <Form
            onSubmit={onSubmit}
            options={{ defaultValues }}
            schema={schema}
            schemaOptions={{ stripUnknown: true }}
        >
            <Stack gap={3}>
                <Stack gap={3}>
                    {participantTypes.map((type) => (
                        <FormNumberStepper
                            key={type.id}
                            defaultValue={type.quantite_min}
                            helperText={t("format.ageRange", {
                                value: { min: type.age_min, max: type.age_max },
                            })}
                            label={type.label}
                            max={type.quantite_max}
                            min={type.quantite_min}
                            name={`participants.${type.id}`}
                        />
                    ))}

                    <FormError name="participants.root" />
                </Stack>
                <FormHidden name="type_produit" />
                <Button isLoading={isLoading} type="submit">
                    {t("Rechercher")}
                </Button>
            </Stack>
        </Form>
    );
}
