import "@fontsource/inter/latin-400.css";
import "@fontsource/inter/latin-600.css";

import "@/styles/index.scss";

import { WidgetAttributesProvider } from "@resamare/widgets-shared";
import { AppProvider } from "./providers/AppProvider";
import { AppRoutes } from "./routes/AppRoutes";
import { SearchWidgetAttributes } from "./hooks/useWidgetAttributes";

type AppProps = {
    widgetAttributes: SearchWidgetAttributes;
};

export function App({ widgetAttributes }: AppProps) {
    return (
        <WidgetAttributesProvider attributes={widgetAttributes}>
            <AppProvider>
                <AppRoutes />
            </AppProvider>
        </WidgetAttributesProvider>
    );
}
