import React from "react";
import ReactDOM from "react-dom/client";
import { initSentry } from "@resamare/widgets-shared";
import { App } from "./App";
import { searchWidgetSchema } from "./hooks/useWidgetAttributes";

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;
initSentry({ dsn: SENTRY_DSN, widgetName: "recherche" });

const widgets = document.querySelectorAll<HTMLDivElement>(".resamare-widget-recherche");

widgets.forEach((widget) => {
    const { dataset } = widget;

    const widgetAttributes = searchWidgetSchema.validateSync({ ...dataset });

    ReactDOM.createRoot(widget).render(
        <React.StrictMode>
            <App widgetAttributes={widgetAttributes} />
        </React.StrictMode>,
    );
});
