import { useParams } from "react-router-dom";
import { Alert, Spinner } from "@resamare/ui";
import { isEqual } from "@resamare/functions";
import { PackageForm } from "../components/PackageForm";
import { PackageSearch, useSearchContext } from "../../search";
import { useGetParticipantTypes } from "../../participant-types";
import { useGetPackages } from "../api/getPackages";

export function PackageFormContainer() {
    const { productTypeId } = useParams() as { productTypeId: string };
    const { search, setSearch } = useSearchContext<PackageSearch>();

    const participantTypesQuery = useGetParticipantTypes({ productTypeId });

    const packagesQuery = useGetPackages({ params: search });

    if (participantTypesQuery.isPending) {
        return <Spinner />;
    }

    if (!participantTypesQuery.data) {
        return <Alert severity="error">Aucun type de participant n'a pû être récupéré</Alert>;
    }

    const onSubmit = (data: PackageSearch) => {
        if (isEqual(data, search)) packagesQuery.refetch();
        setSearch(data);
    };

    return (
        <PackageForm
            defaultValues={{ type_produit: parseInt(productTypeId, 10) }}
            isLoading={packagesQuery.isFetching}
            onSubmit={onSubmit}
            participantTypes={participantTypesQuery.data}
        />
    );
}
