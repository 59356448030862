import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { apiClient } from "../../../config/api-client";
import { Cart, CartLine } from "../../../models";

type DeleteOptionsDTO = {
    cartKey: Cart["panier_cle"];
    lineId: CartLine["id"];
    options: Record<string, unknown>;
};

async function deleteOptions(request: DeleteOptionsDTO): Promise<AxiosInstance> {
    const { cartKey, lineId, options } = request;
    return apiClient.delete(`/panier/${cartKey}/supprimerOptionLignePanier/${lineId}`, {
        data: options,
    });
}

export function useDeleteOptions() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: deleteOptions,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["cart"] }),
    });

    return mutation;
}
