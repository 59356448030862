import { Spinner } from "@resamare/ui";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { NavibusForm, NavibusFormProps } from "../components/NavibusForm";
import { getNavibusSchema } from "../validation/navibusSchema";
import { useDefaultConfigSearch } from "../../../client-details";
import { useGetProducts, useGetProductSections } from "../../../products";
import { useGetParticipantTypes } from "../../../participant-types";
import { getItineraryFromData } from "../utils/getItineraryFromData";
import { NavibusSearch } from "../../../search";

type NavibusFormContainerProps = Pick<NavibusFormProps, "isLoading" | "onSubmit">;

export function NavibusFormContainer({ isLoading, onSubmit }: NavibusFormContainerProps) {
    const queryClient = useQueryClient();
    const { productTypeId } = useParams() as { productTypeId: string };
    const participantTypesQuery = useGetParticipantTypes({ productTypeId });
    const productsQuery = useGetProducts({ productTypeId });
    const productSectionsQuery = useGetProductSections(productsQuery.data?.[0].id);
    const defaultSearch = useDefaultConfigSearch();

    if (
        participantTypesQuery.isPending ||
        productsQuery.isPending ||
        productSectionsQuery.isPending
    ) {
        return <Spinner />;
    }

    if (!participantTypesQuery.data || !productsQuery.data || !productSectionsQuery.data) {
        return null;
    }

    const schema = getNavibusSchema(participantTypesQuery.data, productSectionsQuery.data);

    const handleOnSubmit = (data: NavibusSearch) => {
        queryClient.removeQueries({ queryKey: ["navibus-sessions"] });
        const itinerary = getItineraryFromData(data, productSectionsQuery.data);
        onSubmit({ ...data, itinerary });
    };

    return (
        <NavibusForm
            defaultValues={{ produit_id: productsQuery.data[0].id, ...defaultSearch }}
            isLoading={isLoading}
            onSubmit={handleOnSubmit}
            participantTypes={participantTypesQuery.data}
            productSections={productSectionsQuery.data}
            schema={schema}
        />
    );
}
