import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { ExcursionSearch, useSearchContext } from "../../../search";
import { Product } from "../../../../models";
import { usePrefetchSessionOptionsForm } from "../../../sessions";
import { useCreateCartLineAndNavigate, useHasOptionsToShow } from "../../../options";
import { ExcursionCard } from "./ExcursionCard/ExcursionCard";
import { Path } from "../../../../routes";

type ExcursionProps = {
    excursion: Product;
};

export function Excursion({ excursion }: ExcursionProps) {
    const { search } = useSearchContext<ExcursionSearch>() as { search: ExcursionSearch };
    const navigate = useNavigate();
    const location = useLocation();

    const { sessions } = excursion;

    usePrefetchSessionOptionsForm([sessions[0].id], { participants: search.participants });
    const hasOptions = useHasOptionsToShow(sessions, search.participants);
    const { handleCreateCartLine, isLoading } = useCreateCartLineAndNavigate();

    const navigateToOptions = () => {
        navigate(
            {
                pathname: Path.OPTIONS,
                search: `${createSearchParams({
                    sessions: excursion.sessions.map((session) => session.id),
                    participants: JSON.stringify(search?.participants),
                })}`,
            },
            { state: { previousLocation: location, sessions } },
        );
    };

    const handleOnClick = () => {
        if (!hasOptions) {
            handleCreateCartLine({
                lieu_id: sessions[0].lieux_depart[0].value,
                session_id: sessions[0].id,
                participants: search.participants,
            });
            return;
        }
        navigateToOptions();
    };

    return <ExcursionCard excursion={excursion} isLoading={isLoading} onClick={handleOnClick} />;
}
