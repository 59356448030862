import { SupportedLanguages } from "@resamare/translation";
import { object, string, number, InferType } from "yup";
import { normalizeWidgetData } from "./normalizeWidgetAttributes";

const colorRegex = /^#[0-9A-Fa-f]{3}([0-9A-Fa-f]{3})?$/; // Regex pour valider un code HEX
const cssVariableRegex = /^var\(--[a-zA-Z0-9-_]+\)$/; // Regex pour valider une variable CSS

export type BaseWidgetAttributes = InferType<typeof baseWidgetSchema>;
export type ExtendedWidgetAttributes = InferType<typeof extendedWidgetSchema>;

export const baseWidgetSchema = object()
    .transform(normalizeWidgetData)
    .shape({
        api: string()
            .url("The 'api' attribute must be a valid URL")
            .required("The 'api' attribute is required"),
        couleurComplementaire: string()
            .transform((value) => (value === "" ? undefined : value))
            .matches(
                new RegExp(`${colorRegex.source}|${cssVariableRegex.source}`),
                "The 'data-couleur-complementaire' must be a valid HEX color or a CSS variable",
            ),
        couleurPrincipale: string()
            .transform((value) => (value === "" ? undefined : value))
            .matches(
                new RegExp(`${colorRegex.source}|${cssVariableRegex.source}`),
                "The 'data-couleur-principale' must be a valid HEX color or a CSS variable",
            ),
        langue: string()
            .transform((value) => (value === "" ? undefined : value))
            .oneOf(
                Object.values(SupportedLanguages),
                "The 'data-langue' must be either 'fr' or 'en'",
            ),
        partenaireId: number()
            .transform((value) => (value === "" ? undefined : value))
            .integer("The 'data-partenaire-id' must be an ID"),
    });

export const extendedWidgetSchema = baseWidgetSchema.concat(
    object({
        offsetTop: number().integer("The 'data-offset-top' must be an ID"),
        recherche: string().typeError("The 'data-recherche' must be a valid query"),
        typeProduit: string().matches(
            /^\d+(,\d+)*$/,
            "The 'data-type-produit' attribute must be a comma-separated list of numbers",
        ),
        tokenReservation: string().matches(
            /^$|^[a-f0-9]{16}$/,
            "The 'data-token-reservation' must be a valid token",
        ),
    }),
);
