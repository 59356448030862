import { useTranslation } from "@resamare/translation";
import { Stack, Typography } from "@resamare/ui";
import { formatCurrency, isEmpty } from "@resamare/functions";
import { useCartLineCardContext } from "../CartLineCard";

export function CartLineCardOptions() {
    const { t } = useTranslation();
    const { line } = useCartLineCardContext();

    const { options } = line;

    if (isEmpty(options)) {
        return null;
    }

    return (
        <Stack>
            <Stack direction="row" gap={1}>
                <Typography fontWeight={600}>{t("Options")}</Typography>
            </Stack>
            {options.map((option) => (
                <Stack
                    key={option.tarif_label}
                    direction="row"
                    gap={1}
                    justifyContent="space-between"
                >
                    <Typography>
                        {option.quantite} x {option.tarif_label}
                    </Typography>
                    <Typography>
                        {option.prix_tot > 0 ? formatCurrency(option.prix_tot) : t("Gratuit")}
                    </Typography>
                </Stack>
            ))}
        </Stack>
    );
}
