import { storage } from "@resamare/functions";
import { Spinner } from "@resamare/ui";
import { useAddOptions } from "../api";
import { CartLine } from "../../../models";
import { MeetchForm } from "../components";
import { useDeleteOptions } from "../api/deleteOptions";
import { useMeetch } from "../hooks/useMeetch";

type MeetchContainerProps = {
    line: CartLine;
};

export function MeetchContainer({ line }: MeetchContainerProps) {
    const productIds = line.liste_session
        ? line.liste_session.map((session) => session.produit_id)
        : [line.produit_id];
    const { isLoading, meetchOption } = useMeetch({ productIds });

    const addOptions = useAddOptions();
    const deleteOptions = useDeleteOptions();

    if (isLoading) return <Spinner />;
    if (!meetchOption) return null;

    const isMeetchActive = line.options.some((option) => option.option_id === meetchOption.id);

    const handleOnChange = (value: string) => {
        const cartKey = storage.getWithExpiry<string>("cart");
        if (!cartKey) throw new Error("Cart not found");

        const params = {
            cartKey,
            lineId: line.id,
            options: { [`radio_option_${meetchOption.id}`]: meetchOption.tarifs[0].id },
        };

        if (value === `${line.id}_0`) {
            deleteOptions.mutate(params);
        } else {
            addOptions.mutate(params);
        }
    };

    return (
        <MeetchForm
            id={line.id}
            isLoading={addOptions.isPending || deleteOptions.isPending}
            meetchOption={meetchOption}
            onChange={handleOnChange}
            value={isMeetchActive ? `${line.id}_${meetchOption.tarifs[0].id}` : `${line.id}_0`}
        />
    );
}
