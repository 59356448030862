import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { flat, isEmpty, unique } from "@resamare/functions";
import { OptionDetails, OptionQuantity, ProductUnit } from "../types";

const getUniqueOptionPrices = (
    option: OptionQuantity,
    participants: Record<string, number>,
): Map<number, number> => {
    const prices = new Map();

    option.tarifs.forEach((t) => {
        if (t.type_participant === undefined) return prices.set(t.id, t.prix_ttc);
        if (isEmpty(t.type_participant)) return prices.set(t.id, t.prix_ttc);
        const nbOfParticipants = Object.entries(participants)
            .filter(([id]) => t.type_participant?.some((type) => type.id === id))
            .reduce((sum, [, value]) => sum + value, 0);
        return prices.set(t.id, t.prix_ttc * nbOfParticipants);
    });

    return prices;
};

const getTariffsMapping = (options?: OptionDetails[], participants?: Record<string, number>) => {
    if (!options || !participants) return [];

    const optionsQuantity = options.filter(
        (option) => option.produit_unite === ProductUnit.QUANTITY,
    ) as OptionQuantity[];

    const tariffsWithPrice = optionsQuantity.map((option: OptionQuantity) =>
        option.tarifs
            .filter((tarif) => tarif.prix_ttc !== 0)
            .map((filteredTarif) =>
                option.choix_unique
                    ? {
                          option_id: option.id,
                          tariff_id: filteredTarif.id,
                          unique: true,
                          price: getUniqueOptionPrices(option, participants),
                      }
                    : {
                          option_id: option.id,
                          tariff_id: filteredTarif.id,
                          unique: false,
                          price: filteredTarif.prix_ttc,
                      },
            ),
    );

    return unique(flat(tariffsWithPrice), (tariff) => tariff.tariff_id);
};

const getNamesMapping = (names: string[], ids: string[]) => {
    const mapping: Record<string, string> = {};

    ids.forEach((id) => {
        const correspondingName = names.find((name) => name.indexOf(id) >= 0);
        if (!correspondingName) return;
        if (
            id.startsWith("tarif") &&
            id.replace(/\D/g, "") !== correspondingName.replace(/\D/g, "")
        )
            return;
        mapping[id] = correspondingName;
    });

    return mapping;
};

export const useOptionsTotal = (options: OptionDetails[], participants: Record<string, number>) => {
    const { getValues } = useFormContext();
    const mapping = useMemo(
        () => getTariffsMapping(options, participants),
        [options, participants],
    );
    const formNames = Object.keys(getValues());

    const namesMapping = useMemo(
        () =>
            getNamesMapping(
                formNames,
                mapping.map((item) =>
                    item.unique ? `option_${item.option_id}` : `tarif_${item.tariff_id}`,
                ),
            ),
        [formNames, mapping],
    );

    const mappingWithNames = useMemo(
        () =>
            mapping
                .map((item) => ({
                    ...item,
                    name: namesMapping[
                        item.unique ? `option_${item.option_id}` : `tarif_${item.tariff_id}`
                    ],
                }))
                .filter((item) => item.name),
        [mapping, namesMapping],
    );

    const uniqueMappingWithNames = unique(mappingWithNames, (item) => item.name);

    const values = useWatch({ name: uniqueMappingWithNames.map((item) => item.name) });

    const defaultValues = uniqueMappingWithNames.map(({ price }) =>
        typeof price === "number" ? 0 : price.keys().next().value,
    );

    let total = 0;

    if (isEmpty(values) || values.every((v) => !v)) {
        total = defaultValues.reduce((acc: number, value, index) => {
            const field = uniqueMappingWithNames[index];
            if (!field) return acc;

            if (field.unique) {
                const price = (field.price as Map<number, number>).get(value || 0) || 0;
                return acc + price;
            }
            return acc + (field.price as number) * (value || 0);
        }, 0);
    } else {
        total = values.reduce((acc, value, index) => {
            const field = uniqueMappingWithNames[index];
            if (!field) return acc;
            if (field.unique) return acc + ((field.price as Map<number, number>).get(value) || 0);
            return acc + (field.price as number) * value;
        }, 0);
    }

    return Number.isNaN(total) ? 0 : total;
};
