import { ButtonLink, Drawer, Link, Stack, Typography } from "@resamare/ui";
import { useState } from "react";
import { Trans, useTranslation } from "@resamare/translation";
import { useClientConfig } from "../../../client-details";
import { OverlayHeader } from "../../../../components";
import { ModalLayout } from "../../../../layouts";

export function TermsAndConditionsSection() {
    const { t } = useTranslation();
    const config = useClientConfig();

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    return (
        <Stack gap={3}>
            <Typography>
                <Trans
                    components={{
                        cgv: <Link href={config.cgv} target="_blank" />,
                        personalData: <ButtonLink onClick={openModal} />,
                    }}
                    i18nKey="terms_conditions"
                />
            </Typography>

            <Drawer isOpen={isOpen} maxWidth={560} onClose={closeModal}>
                <OverlayHeader onClose={closeModal}>
                    {t("Politique de gestion des données personnelles")}
                </OverlayHeader>
                <ModalLayout>
                    <Typography component="div">
                        <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: config.cond_perso }}
                        />
                    </Typography>
                </ModalLayout>
            </Drawer>
        </Stack>
    );
}
