/* eslint-disable @typescript-eslint/no-use-before-define */
import { useTranslation } from "@resamare/translation";
import { FormRadio, Stack, Typography } from "@resamare/ui";
import { isEmpty } from "@resamare/functions";
import { PaymentMethod } from "@resamare/models";
import { useClientConfig } from "../../../client-details";

import AmericanExpressLogo from "./assets/american-express.svg";
import AncvLogo from "./assets/ancv.svg";
import CreditCardLogo from "./assets/credit-card.svg";

export function PaymentMethodSection() {
    const { t } = useTranslation();

    const config = useClientConfig();

    if (!config.moyen_paiement_sup || isEmpty(config.moyen_paiement_sup)) {
        return null;
    }

    const options = config.moyen_paiement_sup.map((method) => ({
        value: method,
        label: "",
    }));

    return (
        <Stack gap={3}>
            <Typography variant="title-xs">{t("Méthode de paiement")}</Typography>
            <FormRadio
                label={t("Comment souhaitez-vous payer ?")}
                name="methode-paiement"
                options={[{ value: PaymentMethod["CB"], label: "" }, ...options]}
                renderOption={({ value }) => <PaymentMethodRenderer value={value} />}
                row
            />
        </Stack>
    );
}

type PaymentMethodRendererProps = {
    value: PaymentMethod;
};

function PaymentMethodRenderer({ value }: PaymentMethodRendererProps) {
    const { t } = useTranslation();

    const PAYMENT_METHOD_INFOS = {
        AMEX: { label: "American Express", logo: AmericanExpressLogo },
        ANCV: { label: "Ancv", logo: AncvLogo },
        CB: { label: t("Carte bancaire"), logo: CreditCardLogo },
    };

    if (!Object.prototype.hasOwnProperty.call(PAYMENT_METHOD_INFOS, value)) {
        throw new Error("Unsupported payment method");
    }

    return (
        <Stack alignItems="center" gap={0.25}>
            <img alt={PAYMENT_METHOD_INFOS[value].label} src={PAYMENT_METHOD_INFOS[value].logo} />
            <Typography variant="text-xs">{PAYMENT_METHOD_INFOS[value].label}</Typography>
        </Stack>
    );
}
