import { FormType, SearchModule as SharedSearchModule } from "@resamare/widgets-shared";
import { Intro } from "./Intro";
import { LiaisonForm } from "./LiaisonForm";
import { ExcursionForm } from "./ExcursionForm";
import { NavibusForm } from "./NavibusForm";
import { PackageForm } from "./PackageForm";

const FORMS = {
    [FormType.EXCURSION]: <ExcursionForm />,
    [FormType.LIAISON]: <LiaisonForm />,
    [FormType.NAVIBUS]: <NavibusForm />,
    [FormType.PACKAGE]: <PackageForm />,
};

export function SearchModule() {
    return <SharedSearchModule forms={FORMS} maxWidth={500} renderBefore={Intro} />;
}
