import { Link, Typography } from "../../General";

const allowedTags = ["a", "br"];

/**
 * Formate une chaîne HTML en ne conservant que les balises `<a>` et `<br>`,
 * remplace la balise <p> par son contenu suivi d'un <br>,
 * et transforme les liens en composants React `Link`.
 *
 * @param {string} html - La chaîne HTML à formater.
 * @returns {Array<React.ReactNode>} - Un tableau de nœuds React avec des composants `Link`, `<br />` pour les retours à la ligne, et du texte brut.
 *
 */
export function sanitizeHtml(html: string): Array<React.ReactNode> {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    doc.body.querySelectorAll("*").forEach((node) => {
        const tagName = node.tagName.toLowerCase();

        if (tagName === "p") {
            // Remplace la balise <p> par son contenu suivi d'un <br>
            const parent = node.parentNode;
            if (parent) {
                Array.from(node.childNodes).forEach((child) => parent.insertBefore(child, node));
                const br = document.createElement("br");
                parent.insertBefore(br, node);
            }
            node.remove();
        } else if (!allowedTags.includes(tagName)) {
            node.replaceWith(...node.childNodes);
        } else if (tagName === "a") {
            node.removeAttribute("style");
        }
    });

    return Array.from(doc.body.childNodes).map((node, index) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node as HTMLElement;

            if (
                element.textContent &&
                element.tagName.toLowerCase() === "a" &&
                element.hasAttribute("href")
            ) {
                return (
                    <Link
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        href={element.getAttribute("href") || ""}
                        showNewTabIcon
                        target={element.getAttribute("target") || "_blank"}
                    >
                        {element.textContent}
                    </Link>
                );
            }

            if (element.tagName.toLowerCase() === "br") {
                // eslint-disable-next-line react/no-array-index-key
                return <br key={index} />;
            }
        }

        return node.textContent;
    });
}

type HtmlSanitizerProps = React.ComponentProps<typeof Typography> & {
    html: string;
};

export function HtmlTextSanitizer({ html, ...rest }: HtmlSanitizerProps) {
    if (!html || html.trim() === "") {
        return null;
    }

    const formattedHtml = sanitizeHtml(html);

    return <Typography {...rest}>{formattedHtml}</Typography>;
}
