import { useTranslation } from "@resamare/translation";
import { Alert, ButtonLink } from "@resamare/ui";
import { useCartLineCardContext } from "../CartLineCard";

export function CartLineCardAvailability() {
    const { t } = useTranslation();
    const { line, deleteLine, isLoading } = useCartLineCardContext();

    if (line.dispo) {
        return null;
    }

    return (
        <Alert severity="error" title="Produit non disponible">
            {t(
                "Ce produit n'est plus disponible à la vente. Veuillez le supprimer pour continuer.",
            )}{" "}
            <ButtonLink color="error" isLoading={isLoading} onClick={deleteLine}>
                {t("Supprimer")}
            </ButtonLink>
        </Alert>
    );
}
