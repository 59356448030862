import { Typography } from "@resamare/ui";
import { PropsWithChildren } from "react";
import { useCartLineCardContext } from "../CartLineCard";

export function CartLineCardTitle({ children }: PropsWithChildren) {
    const { line } = useCartLineCardContext();

    return (
        <Typography fontWeight={600} maxLines={2}>
            {children || line.nom}
        </Typography>
    );
}
