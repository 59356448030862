import { Drawer } from "@resamare/ui";
import { dt } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { ExcursionOptionsValues } from "../../excursion";
import { LiaisonSearch, useSearchContext } from "../../../search";
import { SelectedLiaison } from "../types";
import { Liaison } from "../../../../models";
import { OptionsContainer } from "../../../options";
import { OverlayHeader } from "../../../../components";

type LiaisonOptionsProps = {
    isOpen: boolean;
    liaison: Liaison;
    onClose: () => void;
    onSelect: (data: SelectedLiaison) => void;
};
export function LiaisonOptions({ isOpen, liaison, onClose, onSelect }: LiaisonOptionsProps) {
    const { t } = useTranslation();
    const { search } = useSearchContext<LiaisonSearch>() as { search: LiaisonSearch };

    const defaultValues = {
        hour: liaison.heure_depart.value,
        lieu_id: liaison.lieux_depart[0].value,
        participants: search?.participants,
        session_id: liaison.id,
    };

    const handleOnSubmit = (data: Omit<ExcursionOptionsValues, "hour">) => {
        onSelect({
            date_fin: dt.fromISO(dt.removeIsoOffset(liaison.date_fin)),
            prix_apartirde: liaison.prix_apartirde || liaison.prix_total,
            liaison,
            ...data,
        });
        onClose();
    };

    return (
        <Drawer isOpen={isOpen} maxWidth={560} onClose={onClose}>
            <OverlayHeader onClose={onClose}>{t("Options")}</OverlayHeader>
            <OptionsContainer
                defaultValues={defaultValues}
                onSubmit={handleOnSubmit}
                participants={search.participants}
                sessions={[liaison]}
            />
        </Drawer>
    );
}
