import { useTranslation } from "@resamare/translation";
import { Stack, Typography } from "@resamare/ui";
import { useClientConfig } from "../../client-details";

export function SellingDisabledMessage() {
    const { t } = useTranslation();
    const config = useClientConfig();

    return (
        <Stack gap={1}>
            <Typography variant="title-xs">
                {t("La réservation en ligne n'est pas disponible pour le moment.")}
            </Typography>
            {config.texte_vente_desactivee && (
                <Typography component="div">
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: config.texte_vente_desactivee,
                        }}
                    />
                </Typography>
            )}
        </Stack>
    );
}
