import { useParams } from "react-router-dom";
import { Alert, Spinner } from "@resamare/ui";
import { ExcursionForm, ExcursionFormProps } from "../components/ExcursionForm";
import { useGetParticipantTypes } from "../../../participant-types";
import { useDefaultConfigSearch } from "../../../client-details";
import { getExcursionSchema } from "../validation/excursionSchema";

type ExcursionFormContainerProps = Pick<ExcursionFormProps, "isLoading" | "onSubmit">;

export function ExcursionFormContainer({ isLoading, onSubmit }: ExcursionFormContainerProps) {
    const { productTypeId } = useParams() as { productTypeId: string };

    const participantTypesQuery = useGetParticipantTypes({ productTypeId });
    const defaultSearch = useDefaultConfigSearch();

    if (participantTypesQuery.isPending) {
        return <Spinner />;
    }

    if (!participantTypesQuery.data) {
        return <Alert severity="error">Aucun type de participant n'a pû être récupéré</Alert>;
    }

    const schema = getExcursionSchema(participantTypesQuery.data);

    return (
        <ExcursionForm
            defaultValues={{ ...defaultSearch, type_produit: parseInt(productTypeId, 10) }}
            isLoading={isLoading}
            onSubmit={onSubmit}
            participantTypes={participantTypesQuery.data}
            schema={schema}
        />
    );
}
