import { useQuery } from "@tanstack/react-query";
import { dt } from "@resamare/functions";
import { apiClient } from "../../../config/api-client";
import { OptionDetails } from "../types";
import { QueryConfig } from "../../../utils/react-query-utils";

type GetOptionsDTO = {
    date?: Date;
    productIds?: string[];
};

async function getOptions(params: GetOptionsDTO): Promise<OptionDetails[]> {
    const { date, productIds } = params;
    try {
        const response = await apiClient.get("options", {
            params: {
                date,
                produit_id: productIds ? productIds.join(",") : null,
            },
        });
        return response.data;
    } catch (error) {
        return [];
    }
}

export const useGetOptions = (
    params: GetOptionsDTO,
    queryConfig?: QueryConfig<typeof getOptions>,
) => {
    const query = useQuery({
        queryKey: [
            "options",
            { ...params, date: params.date ? dt.toIsoDate(params.date) : undefined },
        ],
        queryFn: () => getOptions(params),
        ...queryConfig,
    });
    return query;
};
