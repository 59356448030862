import { dt, removeEmptyValues } from "@resamare/functions";
import { useWidgetAttributes } from "../../widget-attributes";
import { ExtendedWidgetAttributes } from "../../widget-attributes/baseWidgetSchema";

export function useDefaultConfigSearch() {
    const attributes = useWidgetAttributes<ExtendedWidgetAttributes>();

    if (!attributes.recherche) return null;

    const urlSearchParams = new URLSearchParams(attributes.recherche);

    let defaultSearch: Record<string, string | Date | null | Date[] | string[]> = {
        from: urlSearchParams.get("from"),
        to: urlSearchParams.get("to"),
        "trip-type": urlSearchParams.get("trip-type"),
        type_produit: urlSearchParams.get("type_produit"),
        itinerary: urlSearchParams.getAll("itinerary[]"),
    };

    const participants = urlSearchParams.get("participants");
    if (participants) {
        try {
            defaultSearch = { ...defaultSearch, participants: JSON.parse(participants) };
        } catch {
            throw new Error("Error when parsing participants in data-recherche");
        }
    }

    const dateDeb = urlSearchParams.get("date_deb");
    if (dateDeb && dt.isValid(dateDeb)) {
        defaultSearch = { ...defaultSearch, date_deb: dt.fromISO(dateDeb) };
    }

    const dates = urlSearchParams.getAll("dates[]");
    if (dates && dates.every((date) => dt.isValid(date))) {
        defaultSearch = { ...defaultSearch, dates: dates.map((date) => dt.fromISO(date)) };
    }

    return removeEmptyValues(defaultSearch);
}
