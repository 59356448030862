import { HtmlTextSanitizer, RadioGroup, Stack, Typography } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { OptionPercentage } from "../types";

type MeetchFormProps = {
    id: number;
    isLoading: boolean;
    meetchOption: OptionPercentage;
    onChange: (value: string) => void;
    value: string;
};

export function MeetchForm({ id, isLoading, meetchOption, onChange, value }: MeetchFormProps) {
    const { t } = useTranslation();

    return (
        <Stack gap={1}>
            <Typography fontWeight={600}>{t("Meetch, votre partenaire annulation")}</Typography>

            <HtmlTextSanitizer html={meetchOption.description} variant="text-sm" />

            <RadioGroup
                label=""
                name={`radio_option_${meetchOption.id}`}
                onChange={(e) => onChange(e.target.value)}
                options={[
                    {
                        disabled: isLoading,
                        label: t("Je ne veux pas ajouter d'assurance"),
                        value: `${id}_0`,
                    },
                    {
                        disabled: isLoading,
                        label: t("Assurance Meetch : {{percentage}}% du total de la réservation", {
                            percentage: meetchOption.tarifs[0].pourcentage,
                        }),
                        value: `${id}_${meetchOption.tarifs[0].id}`,
                    },
                ]}
                value={value}
            />
        </Stack>
    );
}
