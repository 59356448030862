import { renderElementsWithDividers } from "@resamare/ui";
import { CartLine as ICartLine } from "../../../models";

import { CartLineCard } from "./CartLineCard/CartLineCard";

type CartLineProps = {
    canDelete?: boolean;
    hideTotal?: boolean;
    line: ICartLine;
};

export function CartLine({ canDelete = true, hideTotal = false, line }: CartLineProps) {
    return (
        <CartLineCard key={line.id} line={line}>
            <CartLineCard.Image />
            <CartLineCard.Content>
                {renderElementsWithDividers([
                    <CartLineCard.Infos key="infos" />,
                    line.participants ? <CartLineCard.Participants key="participants" /> : null,
                    line.options.length ? <CartLineCard.Options key="options" /> : null,
                    line.promotion ? <CartLineCard.Discount key="discount" /> : null,
                    !hideTotal ? <CartLineCard.Subtotal key="subtotal" /> : null,
                ])}
                {canDelete ? <CartLineCard.Delete /> : null}
            </CartLineCard.Content>
            <CartLineCard.PackageMessage />
            <CartLineCard.Availability />
        </CartLineCard>
    );
}
