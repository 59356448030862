import { RadioGroup as MuiRadioGroup, RadioGroupProps as MuiRadioGroupProps } from "@mui/material";
import { FormControl, FormHelperText } from "../_partials";
import { RadioItem } from "./RadioItem";
import { Typography } from "../../General";
import { RadioOption } from "./types";

import styles from "./RadioGroup.module.scss";

export interface RadioGroupProps<OptionType extends RadioOption>
    extends Pick<MuiRadioGroupProps, "defaultValue" | "onChange" | "row" | "value"> {
    helperText?: string;
    inputRef?: React.Ref<HTMLInputElement>;
    isError?: boolean;
    label: string;
    name: string;
    options: OptionType[];
    renderOption?: (option: OptionType) => React.ReactNode;
}

export function RadioGroup<OptionType extends RadioOption>({
    defaultValue,
    helperText = "",
    inputRef,
    isError = false,
    label,
    name,
    onChange,
    options,
    renderOption,
    row = false,
    value,
}: RadioGroupProps<OptionType>) {
    return (
        <FormControl component="fieldset" isError={isError}>
            <Typography className={styles["legend"]} component="legend" variant="label">
                {label}
            </Typography>
            <MuiRadioGroup
                defaultValue={defaultValue || options[0].value}
                name={name}
                onChange={onChange}
                row={row}
                sx={{ columnGap: row && renderOption === undefined ? 1 : 0 }}
                value={value}
            >
                {options.map((option) => (
                    <RadioItem
                        key={option.value}
                        description={option.description}
                        disabled={option.disabled}
                        inputRef={inputRef}
                        label={option.label}
                        renderOption={renderOption}
                        value={option.value}
                    />
                ))}
            </MuiRadioGroup>
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </FormControl>
    );
}
