import { useTranslation } from "@resamare/translation";
import { Stack, Typography } from "@resamare/ui";
import { formatCurrency } from "@resamare/functions";
import { useCartLineCardContext } from "../CartLineCard";

export function CartLineCardParticipants() {
    const { t } = useTranslation();
    const { line } = useCartLineCardContext();

    const nbOfParticipants = line.participants.reduce(
        (acc, currentValue) => acc + parseInt(currentValue.quantite, 10),
        0,
    );

    return (
        <Stack>
            <Stack direction="row" gap={1}>
                <Typography fontWeight={600}>
                    {t("Participant", { count: nbOfParticipants })}
                </Typography>
            </Stack>
            {line.participants.map((type) => {
                const quantity = parseInt(type.quantite, 10);
                if (quantity === 0) return null;
                return (
                    <Stack key={type.id} direction="row" gap={1} justifyContent="space-between">
                        <Typography>
                            {quantity} x {type.nom}
                        </Typography>
                        {type.prix_tot ? (
                            <Typography>{formatCurrency(type.prix_tot)}</Typography>
                        ) : null}
                    </Stack>
                );
            })}
        </Stack>
    );
}
