import { useQuery } from "@tanstack/react-query";

import { FormType, ProductType } from "../types";
import { apiClient } from "../../../config";
import { useWidgetAttributes } from "../../widget-attributes";
import { ExtendedWidgetAttributes } from "../../widget-attributes/baseWidgetSchema";

async function getProductTypes(allowedTypes?: string[]): Promise<ProductType[]> {
    const { data } = await apiClient.get<ProductType[]>("/type-produit");

    let filteredProductTypes = data;

    if (allowedTypes) {
        filteredProductTypes = filteredProductTypes
            .filter((type: ProductType) => allowedTypes.includes(type.value))
            .sort(
                (a: ProductType, b: ProductType) =>
                    allowedTypes.indexOf(a.value) - allowedTypes.indexOf(b.value),
            );
    }

    const productTypesWithActivityType = filteredProductTypes.map((type) => ({
        ...type,
        type_form: type.type_form || FormType.EXCURSION,
    }));

    return productTypesWithActivityType;
}

export function useGetProductTypes() {
    const config = useWidgetAttributes<ExtendedWidgetAttributes>();

    const query = useQuery({
        queryKey: ["product-types"],
        queryFn: () => getProductTypes(config.typeProduit?.split(",")),
    });

    return query;
}
