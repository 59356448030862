import { Stack } from "@resamare/ui";
import { PropsWithChildren } from "react";

import styles from "./CartLineCardContent.module.scss";

export function CartLineCardContent({ children }: PropsWithChildren) {
    return (
        <Stack className={styles["cart-line__content"]} gap={2}>
            {children}
        </Stack>
    );
}
