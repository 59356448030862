import { useTranslation } from "@resamare/translation";
import { ButtonLink, FormDate, FormDateRange, FormError, Stack } from "@resamare/ui";
import { useFormContext, useWatch } from "react-hook-form";
import { dt } from "@resamare/functions";
import { TripType } from "../../../../search";
import { useWidgetAttributes } from "../../../../widget-attributes";

export function LiaisonFormDates() {
    const { t } = useTranslation();

    const attributes = useWidgetAttributes();

    const { getValues, setValue } = useFormContext();

    const tripType = useWatch({
        name: "trip-type",
        defaultValue: getValues("trip-type") || TripType.ROUNDTRIP,
    });

    const handleSwitchToRoundtrip = () => {
        const selectedDate = getValues("dates[0]");
        setValue("trip-type", TripType.ROUNDTRIP);
        setValue("dates", [selectedDate, selectedDate]);
    };

    return tripType === TripType.ROUNDTRIP ? (
        <FormDateRange
            label={t("Dates de voyage")}
            locale={attributes.langue}
            minDate={dt.now()}
            name="dates"
        />
    ) : (
        <div>
            <FormDate
                label={t("Départ le")}
                locale={attributes.langue}
                minDate={dt.now()}
                name="dates.0"
            />
            <FormError name="dates" />
            <Stack alignItems="flex-end">
                <ButtonLink onClick={handleSwitchToRoundtrip}>
                    + {t("Ajouter un retour")}
                </ButtonLink>
            </Stack>
        </div>
    );
}
