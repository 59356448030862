export { flat, min } from "./array";
export { alphabetical, isEqual, groupBy, unique } from "./collection";
export { dt, DateFormat } from "./date";
export { createAndSubmitForm, getInputNames, objectToFormData } from "./form";
export { isElementFocusable, isInViewport, isBuiltWithWix } from "./html";
export { deepMerge, get, isEmpty, filterItemsByProperties, removeEmptyValues } from "./object";
export { storage } from "./storage";
export { capitalize, formatCurrency, removeAccents, removeHtml, slug, uid } from "./string";

export function ensure<T>(argument: T | undefined | null, context?: Record<string, any>): T {
    if (argument === undefined || argument === null) {
        const error = new TypeError("This value was promised to be there.");

        if (context) {
            (error as any).context = context;
        }

        throw error;
    }

    return argument;
}
