import { useEffect, useRef } from "react";
import { isInViewport } from "@resamare/functions";
import { Button, Stack } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { LiaisonResumeItem } from "./LiaisonResumeItem";
import { SelectedLiaison } from "../types";
import { LiaisonSearch, TripType, useSearchContext } from "../../../search";
import { Step } from "./Step/Step";
import { useCreateCartLineAndNavigate } from "../../../options";

type LiaisonResumeProps = {
    liaisons: SelectedLiaison[];
};

export function LiaisonResume({ liaisons }: LiaisonResumeProps) {
    const { t } = useTranslation();
    const { search } = useSearchContext<LiaisonSearch>() as { search: LiaisonSearch };

    const isDisabled =
        search["trip-type"] === TripType.ROUNDTRIP ? liaisons.length < 2 : liaisons.length < 1;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!ref.current || isDisabled) return;
        if (isInViewport(ref.current, 400)) return;
        ref.current.scrollIntoView({ block: "center", behavior: "smooth" });
    }, [ref, isDisabled]);

    const { handleCreateCartLine, isLoading } = useCreateCartLineAndNavigate();

    const handleOnClick = async () => {
        handleCreateCartLine(liaisons);
    };

    const [outboundLiaison, returnLiaison] = liaisons;

    return (
        <div ref={ref}>
            <Stack gap={3}>
                <Step
                    isCompleted={false}
                    isDisabled={isDisabled}
                    label={t("Récapitulatif de votre trajet")}
                    onClickEdit={() => null}
                    step={search["trip-type"] === TripType.ROUNDTRIP ? 3 : 2}
                />

                {!isDisabled ? (
                    <Stack gap={4}>
                        <div style={{ display: "flex", flexWrap: "wrap", gap: 24 }}>
                            <LiaisonResumeItem
                                liaison={outboundLiaison.liaison}
                                selectedDeparture={outboundLiaison.lieu_id}
                                type="outbound"
                            />
                            {returnLiaison ? (
                                <LiaisonResumeItem
                                    liaison={returnLiaison.liaison}
                                    selectedDeparture={returnLiaison.lieu_id}
                                    type="return"
                                />
                            ) : null}
                        </div>

                        <Stack alignItems="center" direction="row" justifyContent="flex-end">
                            <Button isLoading={isLoading} onClick={handleOnClick}>
                                {t("Confirmer")}
                            </Button>
                        </Stack>
                    </Stack>
                ) : null}
            </Stack>
        </div>
    );
}
