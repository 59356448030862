import { renderElementsWithDividers } from "@resamare/ui";
import { CartLine as ICartLine } from "../../../models";
import { CartLineCard } from "./CartLineCard/CartLineCard";

type PackageLineProps = {
    line: ICartLine;
    canDelete?: boolean;
};

export function PackageLine({ line, canDelete = true }: PackageLineProps) {
    return (
        <CartLineCard key={line.id} line={line} variant="outlined">
            <CartLineCard.Content>
                <CartLineCard.Title />
                <CartLineCard.ListSessions />
                {renderElementsWithDividers([
                    line.participants ? <CartLineCard.Participants key="participants" /> : null,
                    line.options.length ? <CartLineCard.Options key="options" /> : null,
                    line.promotion ? <CartLineCard.Discount key="discount" /> : null,
                    <CartLineCard.Subtotal key="subtotal" />,
                ])}
                {canDelete ? <CartLineCard.Delete /> : null}
            </CartLineCard.Content>
            <CartLineCard.PackageMessage />
            <CartLineCard.Availability />
        </CartLineCard>
    );
}
