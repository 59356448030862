import "react-datepicker/dist/react-datepicker.css";
import { useElementSize } from "@resamare/react-hooks";
import DatePicker, { registerLocale } from "react-datepicker";

import fr from "date-fns/locale/fr";

import { useCallback } from "react";
import { FormHelperText } from "../_partials";
import { Day, Header, Input } from "../FormDate/_partials";
import { DateFieldProps } from "../FormDate/DateField";
import { DatePortal } from "../FormDate/_partials/DatePortal";

import styles from "../FormDate/DateField.module.scss";

export interface DateFieldRangeProps extends Omit<DateFieldProps, "onChange" | "value"> {
    onChange: (dates: [Date | null, Date | null]) => void;
    value: [Date | null, Date | null];
}

registerLocale("fr", fr);

const CALENDAR_MAX_WIDTH = 500;

export function DateFieldRange({
    availableDates,
    blockedDates,
    excludeDates,
    helperText,
    inline = false,
    inputRef,
    isError,
    label,
    locale = "fr",
    maxDate,
    minDate,
    name,
    onChange,
    portalId,
    value,
}: DateFieldRangeProps) {
    const [calendarRef, { width }] = useElementSize();

    const monthsShown = inline ? Math.floor((width || 0) / CALENDAR_MAX_WIDTH) + 1 : 1;

    const handleTouchStart = useCallback((e: TouchEvent) => e.stopPropagation(), []);

    const handleCalendarOpen = () => {
        document.addEventListener("touchstart", handleTouchStart, true);
    };

    const handleCalendarClose = () => {
        document.removeEventListener("touchstart", handleTouchStart, true);
    };

    return (
        <div ref={calendarRef}>
            <DatePicker
                calendarClassName={styles.calendar}
                customInput={
                    <Input inputName={name} inputRef={inputRef} isError={isError} label={label} />
                }
                dateFormat="d MMM"
                endDate={value[1] || undefined}
                excludeDates={excludeDates}
                formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 2)}
                highlightDates={[
                    { available: availableDates || [] },
                    { blocked: blockedDates || [] },
                ]}
                includeDates={availableDates}
                inline={inline}
                locale={locale}
                maxDate={maxDate || undefined}
                minDate={minDate || undefined}
                monthsShown={monthsShown}
                onCalendarClose={handleCalendarClose}
                onCalendarOpen={handleCalendarOpen}
                onChange={onChange}
                portalId={portalId || "resamare-calendar-root"}
                renderCustomHeader={(params) => (
                    <Header {...params} locale={locale} monthsShown={monthsShown} />
                )}
                renderDayContents={Day}
                selectsRange
                startDate={value[0] || undefined}
                weekDayClassName={() => styles["weekday"]}
                withPortal={!inline}
            />
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            {!portalId ? <DatePortal /> : null}
        </div>
    );
}
