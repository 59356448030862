import { Alert, EmailIcon, Link, PhoneIcon, Stack, Typography } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { useClientConfig } from "../../client-details";

export function ContactInformations() {
    const { t } = useTranslation();

    const config = useClientConfig();

    return (
        <Alert severity="info" showIcon={false}>
            <Typography component="span">
                {t(
                    "Pour réserver un horaire de départ non disponible à la vente en ligne, contactez-nous directement.",
                )}
            </Typography>
            <Stack alignItems="center" component="span" direction="row" gap={1}>
                <PhoneIcon size="small" />
                <Link href={`tel:${config.telephone}`}>{config.telephone}</Link>
            </Stack>
            <Stack alignItems="center" component="span" direction="row" gap={1}>
                <EmailIcon size="small" />
                <Link href={`mailto:${config.email}`}>{config.email}</Link>
            </Stack>
        </Alert>
    );
}
