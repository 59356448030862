/* eslint-disable @typescript-eslint/no-use-before-define */
import { useFormContext, useFormState, useWatch } from "react-hook-form";
import { removeHtml, slug, unique } from "@resamare/functions";
import { useEffect } from "react";
import { ReadMore, Typography } from "../../General";
import { Stack } from "../../Layout";
import { DynamicField } from "./DynamicField";
import { Section } from "./types";
import { SECTION_PREFIX } from "./config";
import { FormError } from "../_partials/FormError/FormError";
import { HtmlTextSanitizer } from "../../Utils";

type FormBuilderSectionProps = {
    section: Section;
    showFullSectionDescription?: boolean;
};

function getFieldsToWatch(section: Section): string[] {
    let fields: string[] = [];
    const filteredRules = section.validation?.filter((rule) => rule.fields?.length >= 2);

    if (!filteredRules) return [];

    filteredRules.forEach((rule) => {
        fields = [...fields, ...rule.fields];
    });

    return unique(fields);
}

export function FormBuilderSection({
    section,
    showFullSectionDescription = false,
}: FormBuilderSectionProps) {
    const isRequired = section.validation?.find((rule) =>
        Object.prototype.hasOwnProperty.call(rule, "required"),
    )?.required;

    const { trigger } = useFormContext();
    const { isSubmitted } = useFormState();

    const fieldNames = getFieldsToWatch(section);
    const fieldWatchers = useWatch({ name: fieldNames, disabled: !isSubmitted });

    useEffect(() => {
        if (isSubmitted) {
            trigger(slug(`${SECTION_PREFIX}-${section.id}`));
        }
    }, [fieldWatchers]);

    return (
        <Stack gap={3}>
            <div id={slug(`${SECTION_PREFIX}-${section.id}`)}>
                <Stack gap={1}>
                    <Typography variant="title-xs">
                        {section.title} {isRequired ? "*" : null}
                    </Typography>
                    <SectionDescription
                        section={section}
                        showFullSectionDescription={showFullSectionDescription}
                    />
                </Stack>

                <FormError name={slug(`${SECTION_PREFIX}-${section.id}`)} />
            </div>
            <Stack gap={2}>
                {section.fields.map((field) => (
                    <DynamicField key={field.name} data={field} />
                ))}
            </Stack>
        </Stack>
    );
}

function SectionDescription({
    section,
    showFullSectionDescription = false,
}: FormBuilderSectionProps) {
    if (!section.short_description && !section.description) {
        return null;
    }

    const shortDescription = section.short_description ? (
        <Typography>{removeHtml(section.short_description)}</Typography>
    ) : null;

    const description = section.description ? (
        <Typography component="div">
            <HtmlTextSanitizer html={section.description} />
        </Typography>
    ) : null;

    if (showFullSectionDescription) {
        return (
            <div>
                {shortDescription}
                {description}
            </div>
        );
    }

    return (
        <ReadMore nbOfLines={3}>
            {shortDescription}
            {description}
        </ReadMore>
    );
}
