import { Image } from "@resamare/ui";
import { useCartLineCardContext } from "../CartLineCard";

import styles from "./CartLineCardImage.module.scss";

export function CartLineCardImage() {
    const { line } = useCartLineCardContext();
    return (
        <div className={styles["cart-line__image"]}>
            <Image src={line.image[0]} />
        </div>
    );
}
