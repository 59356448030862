import { Box } from "@mui/material";
import { ToastContainer, ToastContainerProps } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { CloseIcon } from "../../General";
import { Portal } from "../../Utils";

function CloseButton() {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <CloseIcon />
        </Box>
    );
}

export const toastifyConfig: ToastContainerProps = {
    theme: "colored",
    closeButton: <CloseButton />,
    style: {
        zIndex: "calc(var(--resamare-zIndex-drawer) + 1)",
        // "--toastify-text-color-dark": theme.palette.success.contrastText,
        "--toastify-color-error": "var(--resamare-palette-error-main)",
        "--toastify-text-color-error": "var(--resamare-palette-common-white)",
        "--toastify-color-success": "var(--resamare-palette-success-main)",
        "--toastify-text-color-success": "var(--resamare-palette-common-white)",
    } as React.CSSProperties,
};

export function ToastRenderer() {
    return (
        <Portal>
            <ToastContainer {...toastifyConfig} />
        </Portal>
    );
}
